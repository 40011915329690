//
// _.scss
//

.cafe-css {
    body {
        font-family: $font-noto !important;
    }
    
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        font-family: $font-libre !important;
    }
    
    #topnav {
        font-family: $font-noto !important;
        font-size: 11px !important;
    }
    
    .title-heading {
        font-family: $font-kaushan !important;
    }
    
    .title-heading .title {
        font-size: 120px !important;
        line-height: 1;
    }
    
    @media (max-width: 768px) {
        .title-heading .title {
            font-size: 80px !important;
        }
    }
    
    @media (max-width: 767px) {
        .title-heading .title {
            font-size: 60px !important;
        }
    }
}