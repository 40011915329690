//
// _portfolio.scss
//
.portfolio {
    @each $name,
    $value in $colors {
        &.portfolio-#{$name} {
            .title,
            .lightbox-icon a {
                &:hover {
                    color: $value !important;
                }
            }

            .btn-icon {
                background-color: $value !important;
            }
        }
    }
}

// Filter
.container-filter {
    li {
        margin: 0px 15px 10px !important;
        font-size: 14px;
        letter-spacing: 0.8px;
        cursor: pointer;
        transition: all 0.5s ease;
        color: $muted !important;
        border-bottom: 1px solid transparent;
        font-weight: 500;
        &.active,
        &:hover {
            color: $dark !important;
            border-bottom-color: $dark;
        }
    }
}

.spacing {
    padding: 7px;
}

//Portfolio post
.portfolio {
    transition: all 0.5s ease;
    &.portfolio-classic {
        .pop-icon {
            position: absolute;
            top: 70%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            text-align: center;
            transition: all 0.5s ease;
            opacity: 0;
            .btn-icon {
                color: $white;
            }
        }
        &:hover {
            .pop-icon {
                top: 50%;
                opacity: 1;
            }
        }
    }
    &.portfolio-creative {
        .lightbox-icon {
            position: absolute;
            top: 20px;
            right: 20px;
            transform: scale(0);
            transition: all 0.5s ease;
        }
        img {
            transform: scale(1.1);
            transition: all 0.5s ease;
        }
        .content {
            position: absolute;
            bottom: 5px;
            left: 5px;
            opacity: 0;
            transition: all 0.5s ease;
        }
        &:hover {
            img {
                transform: scale(1);
            }
            .lightbox-icon { 
                transform: scale(1.5);
            }
            .content {
                opacity: 1;
            }
        }
    }
    &.portfolio-modern {
        .content {
            position: absolute;
            bottom: -50px;
            left: 0;
            right: 0;
            transition: all 0.5s ease;
            background-color: $white;
            opacity: 0;
        }
        &:hover {
            transform: translateY(-5px);
            .content {
                bottom: 20px;
                opacity: 1;
            }
        }
    }
    &.portfolio-masonry {
        .content {
            position: absolute;
            bottom: 10px;
            left: 10px;
            right: 10px;
            transform: scale(0);
            transition: all 0.5s ease;
        }
        &:hover {
            .content {
                transform: scale(1);
            }
        }
    }
    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: all 0.5s ease;
    }
    &:hover {
        .overlay {
            backdrop-filter: blur(6px);
            background-color: rgba($white, 0.8);
        }
    }
}

//Tobii Lighbox
.tobii__btn svg {
    height: 30px;
    width: auto;
}
.tobii__counter {
    font-size: 16px;
}
.tobii-zoom {
    display: block !important;
}

.tobii-zoom__icon {
    display: none;
}

#grid {
    padding: 0 !important;
}

//Shop page css
.shop-advertise {
    .ad-image {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 24px;
        height: 280px;
        transition: all 0.3s ease;

        @media (max-width: 768px) {
            height: 200px;
            right: -10px;
        }

        @media (max-width: 425px) {
            height: 150px;
            right: 8px;
        }
    }
    &:hover {
        .ad-image {
            transform: translateY(-50%) scale(1.05);
        }
    }
}

//Shop list
.shop-list {
    .qty-icons{
        input{
            &::placeholder{
                color:$white;
            }
        }
    }
    .shop-image {
        .qty-icons{
            input{
                &::placeholder{
                    color:$white;
                }
            }
        }
        .shop-icons,
        .qty-icons,
        .overlay-work { 
            position: absolute;
            opacity: 0; 
            transition: all 0.5s ease;
            
        }

        .product-image {
            transition: all 0.5s ease;
        }

        .overlay-work {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            .out-stock {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }

        .shop-icons  {
            top: 15px;
            left: 15px;
            z-index: 2;
        }
        .qty-icons {
            bottom: 15px;
            text-align: center;
            right: 0;
            left: 0;
            z-index: 2;
            transform: translateY(100px);
        }
        &:hover {
            .shop-icons,
            .qty-icons,
            .overlay-work {
                opacity: 1;
            }
            .product-image {
                opacity: 0;
            }
            .qty-icons {
                transform: translateY(0);
            }
        }
    }

    .shop-icons,
    .qty-icons {
        .btn {
            &.btn-icon {
                width: 38px;
                height: 38px;
            }
        }
    }
    .content {
        .product-name {
            transition: all 0.5s ease;
            &:hover {
                color: $primary !important;
            }
        }
    }
    .label {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 2;
    }
    .qty-btn {
        pointer-events: none; 
        width: 65px !important;
        padding-left: 16px !important;
        @media (max-width: 1024px) {
            padding-left: 0;
        }
    }
}