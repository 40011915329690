//
// _footer.scss
//
.footer {
    position: relative;
    color: $gray-500;
    .footer-py-60 {
        padding: 60px 0;        
    }
    .footer-py-30 {
        padding: 30px 0;        
    }
    .footer-head {
        letter-spacing: 1px;         
        font-weight: 500;
        color: $light;
    }
    
    .text-foot {
        color: $gray-500;
    }

    .footer-subscribe, .foot-subscribe {
        .form-control {
            background-color: lighten($black, 10%);
            border: 1px solid lighten($black, 10%);
            color: $light !important;
        }
        input {
            padding: 9px 20px;
            padding-right: 48px !important;
            &::placeholder{
                color: $gray-500;
            }
        }
        button {
            top: 4px;
            right: 5px;
            &.btn {
                height: 44px;
                width: 44px;
            }
        }
        &.foot-white {
            .form-control {
                color: $gray-500;
            }
        }
    }
    
    .footer-list {
        margin-bottom: 0;
        li {
            margin-bottom: 10px;
            a {
                transition: all 0.5s ease;
                &:hover {
                    color: lighten($gray-500, 20%);
                }
            }   
            &:last-child{
                margin-bottom: 0;
            }         
        }
    }

    .footer-border,
    .footer-bar {
        border-top: 1px solid lighten($footer, 3%);
    }
    .footer-border-bottom {
        border-bottom: 1px solid lighten($footer, 3%);
    }
    .border {
        border-color: lighten($footer, 3%) !important;
    }

    &.footer-light {
        background-color: $white;
        .footer-head {
            color: $dark;
        }
        .text-foot {
            color: $muted;
        }
        .footer-subscribe, .foot-subscribe {
            .form-control {
                background-color: $light;
                border: 1px solid $gray-200;
                color: $light;
            }
            &.foot-white {
                .form-control {
                    color: $gray-500;
                }
            }
            input{
                &::placeholder{
                    color: $gray-500;
                }
            }
        }
        .footer-list {
            li {
                a {
                    &:hover {
                        color: darken($gray-500, 20%);
                    }
                }   
                &:last-child{
                    margin-bottom: 0;
                }         
            }
        }
        .footer-border,
        .footer-bar {
            border-top: 1px solid $gray-200;
        }
        
        .footer-border-bottom {
            border-bottom: 1px solid $gray-200;
        }
        .border {
            border-color: $gray-200 !important;
        }
        .social-icon {
            &.foot-social-icon {
                li {
                    a {
                        color: $gray-400;
                        border-color: $gray-400;
                    }
                }
            }
        }
    }
}