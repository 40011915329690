//
// general.scss
//

body {
    font-family: $font-family-secondary;
    overflow-x: hidden !important;
    font-size: $font-size-base;
    color: $dark !important;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $font-family-base;
    line-height: 1.5;
    font-weight: 500;
}
::selection {
    background: rgba($primary, 0.9);
    color: $white;
}
a {
    text-decoration: none !important;
    cursor: pointer;
}
p {
    line-height: 1.6;
}

.fw-medium {
    font-weight: 500;   
}

.fw-semibold {
    font-weight: 600;   
}

.switcher-button {
    .t-dark, 
    .t-rtl-light {
        display: inline-flex;
    }
    .t-ltr-light,
    .t-light,
    .t-rtl-dark,
    .t-ltr-dark {
        display: none;
    }
}